import { Button, Heading, InputText, Paragraph } from "@reamrd/styledoubler";
import { Auth } from "aws-amplify";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SDCountry } from "../types";
import mixpanel from "mixpanel-browser";
import { timeNowUnix } from "../tools/time";
import ReactPixel from "react-facebook-pixel";
import TiktokPixel from "tiktok-pixel";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { readFromLocalStorage } from "../tools/client-storage";

ReactPixel.init("337984194434000");
// TiktokPixel.init("CCG77KRC77U5BD5RGOTG"); //TEST Pixel - need to change
TiktokPixel.init("CCFGKP3C77UB2PF1IH0G"); //Styledoubler Pixel
ReactGA.initialize("G-TE3HCHT1CL");
// TagManager.initialize({
//   gtmId: "G-TE3HCHT1CL",
// });

mixpanel.init("9eb3b72365fd19e56fe9dd1c17fb4326");

const ConfirmRegisterPage = () => {
  let navigate = useNavigate();
  const localState = useLocation();

  const [code, codeSet] = React.useState("");
  const [errorRegistrationMessaage, setErrorRegistrationMessaage] =
    React.useState("");

  let token: string;

  const getToken = async () => {
    if (token) {
      return token;
    } else {
      const result = await Auth.currentAuthenticatedUser();

      return result.signInUserSession.idToken.jwtToken;
    }
  };

  const setCountryForNewlyRegisteredUser = async (country: SDCountry) => {
    //Uncomment it for final release, this code will add the country for newly registered influencer
    await fetch(
      `https://api.styledoubler.com/api/influencer/country/${country}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: `Bearer ${await getToken()}`,
        },
      }
    );
  };

  const setUtmContentForNewlyRegisteredUser = async (utmContent: string) => {
    await fetch(
      `https://api.styledoubler.com/api/influencer/utm-content/${utmContent}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: `Bearer ${await getToken()}`,
        },
      }
    );
  };

  const confirmSignUp = async () => {
    let hasError = false;

    const { email, password } = localState.state as {
      email: string;
      password: string;
    };

    const localStorageSource = readFromLocalStorage<{ source: string }>(
      "utm-source"
    );
    const { country } = readFromLocalStorage<{ country: SDCountry }>("country");

    try {
      await Auth.confirmSignUp(email, code);

      await Auth.signIn({
        username: email,
        password: password,
      });

      const influencerId = (await Auth.currentAuthenticatedUser()).attributes
        .sub;

      const dataForTracking = {
        user_id: influencerId,
        email,
        country,
        account_created_at: timeNowUnix(),
        source: localStorageSource.source,
        platform: "web",
      };

      const eventName = "user_account_created";

      try {
        mixpanel.track(eventName, dataForTracking);
      } catch (error) {
        console.log("Mixpanel error:", error);
      }

      try {
        ReactGA.event(eventName, dataForTracking);
      } catch (error) {
        console.log("GA4 error:", error);
      }

      try {
        ReactPixel.trackCustom(eventName, dataForTracking);
      } catch (error) {
        console.log("Facebook pixel error:", error);
      }

      try {
        TiktokPixel.track("CompleteRegistration", dataForTracking);
      } catch (error) {
        console.log("TiktokPixel error:", error);
      }
    } catch (error) {
      console.log(error);

      setErrorRegistrationMessaage("Oops, wrong code?");
      hasError = true;
    }

    if (!hasError) {
      try {
        await setCountryForNewlyRegisteredUser(country);
        await setUtmContentForNewlyRegisteredUser(localStorageSource.source);
      } catch (error) {
        await new Promise((res) => {
          const timeout = setTimeout(async () => {
            await setCountryForNewlyRegisteredUser(country);
            await setUtmContentForNewlyRegisteredUser(
              localStorageSource.source
            );
            res(clearTimeout(timeout));
          }, 2000);
        });
      }

      navigate("/register/approved");
    }
  };

  return (
    <>
      <div className="px-4 mt-16">
        <Heading variant="H4" text="Verify Your Email" />

        <div className="mt-4 leading-5">
          <Paragraph
            variant="P2"
            text="We’ve sent you a code via email. Please check your email inbox and enter the code to confirm the registration"
          />
        </div>
      </div>

      <div className="px-4 mt-10">
        <InputText
          value={code}
          onChange={codeSet}
          title="Verification Code"
          placeholder="Code"
          required
          type="number"
          isTouched={!!errorRegistrationMessaage}
          validationError={
            errorRegistrationMessaage ? errorRegistrationMessaage : undefined
          }
        />
      </div>

      <div className="flex flex-col mb-4 mx-4 mt-2">
        <Button disabled={!code} onAsyncClick={confirmSignUp} text="Confirm" />
      </div>
    </>
  );
};

export default ConfirmRegisterPage;
