import { Heading, Image, Paragraph } from "@reamrd/styledoubler";
import * as React from "react";

const appStoreImg = require("../images/appStore.png");
const googlePlayImg = require("../images/googlePlay.png");

const RegisterApprovedPage = () => {
  return (
    <div className="w-full h-full px-4 flex flex-col items-center pt-16">
      <IconLogo />

      <div className="w-full mb-4 leading-7">
        <Heading
          variant="H5"
          text="Congratulations! Your StyleDoubler account has been successfully created."
        />
      </div>

      <div className="w-full mb-8 leading-5">
        <Paragraph
          variant="P2"
          text="Please continue the registration process by logging in with your email and password in the StyleDoubler for Creators app. See you!"
        />
      </div>

      <div className="w-full my-3">
        <Heading variant="H6" text="Don’t have the app yet?" />
      </div>

      <div className="grid grid-cols-2 gap-2">
        <div
          onClick={() =>
            window.open(
              "https://apps.apple.com/id/app/styledoubler-for-creators/id1590603600",
              "_blank"
            )
          }
          className="cursor-pointer"
        >
          <Image imageUrl={appStoreImg} />
        </div>
        <div
          onClick={() =>
            window.open(
              "https://play.google.com/store/apps/details?id=com.styledoubler.app",
              "_blank"
            )
          }
          className="cursor-pointer"
        >
          <Image imageUrl={googlePlayImg} />
        </div>
      </div>
    </div>
  );
};

const IconLogo = () => {
  return (
    <svg width="301" height="273" viewBox="0 0 301 273" fill="none">
      <g filter="url(#filter0_d_0_1)">
        <g clipPath="url(#clip0_0_1)">
          <rect
            x="48"
            y="64.5988"
            width="156.862"
            height="156.862"
            rx="32"
            transform="rotate(-15 48 64.5988)"
            fill="#5F1BA4"
          />
          <g opacity="0.8" filter="url(#filter1_f_0_1)">
            <circle
              cx="85.3957"
              cy="54.42"
              r="76.4395"
              transform="rotate(-15 85.3957 54.42)"
              fill="#912BEE"
            />
          </g>
          <g filter="url(#filter2_f_0_1)">
            <circle
              cx="228.237"
              cy="193.924"
              r="67.4015"
              transform="rotate(-15 228.237 193.924)"
              fill="#C46772"
            />
          </g>
          <g opacity="0.8" filter="url(#filter3_f_0_1)">
            <ellipse
              cx="48.2159"
              cy="247.077"
              rx="156.709"
              ry="97.8854"
              transform="rotate(-15 48.2159 247.077)"
              fill="#2340F4"
            />
          </g>
          <path
            d="M115.316 146.79C114.558 146.938 113.776 146.926 113.02 146.755C112.263 146.583 111.546 146.256 110.915 145.793L94.9931 137.228C94.3866 136.763 93.8799 136.176 93.5052 135.503C93.1537 134.827 92.9087 134.1 92.7791 133.349C92.4652 131.666 92.7243 129.932 93.5134 128.433L124.951 72.2086L138.477 80.2882L108.979 133.339L140.867 124.795L144.649 138.909L115.316 146.79Z"
            fill="white"
          />
          <path
            d="M172.458 93.1001C173.212 92.9568 173.989 92.972 174.74 93.1445C175.493 93.3169 176.203 93.6433 176.83 94.1035L192.74 102.665C193.347 103.13 193.854 103.717 194.228 104.389C194.583 105.063 194.83 105.789 194.959 106.541C195.272 108.223 195.013 109.956 194.223 111.455L162.773 167.695L149.263 159.606L178.781 106.573L146.885 115.12L143.105 101.013L172.458 93.1001Z"
            fill="white"
          />
        </g>
      </g>
      <g filter="url(#filter4_d_0_1)">
        <rect
          x="214.635"
          y="142"
          width="72"
          height="72"
          rx="36"
          transform="rotate(15 214.635 142)"
          fill="#15CB87"
          shapeRendering="crispEdges"
        />
        <g clipPath="url(#clip1_0_1)">
          <path
            d="M249.087 204.031L249.95 200.811C250.408 199.103 250.168 197.283 249.284 195.752C248.4 194.221 246.944 193.104 245.236 192.646L232.357 189.195C230.649 188.737 228.829 188.977 227.298 189.861C225.767 190.745 224.65 192.201 224.192 193.909L223.329 197.129"
            stroke="white"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M240.522 184.481C244.078 185.434 247.734 183.323 248.687 179.767C249.64 176.211 247.529 172.555 243.973 171.602C240.416 170.649 236.761 172.76 235.808 176.316C234.855 179.872 236.965 183.528 240.522 184.481Z"
            stroke="white"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <g filter="url(#filter5_d_0_1)">
        <circle cx="270" cy="129" r="9" fill="#15CB87" />
      </g>
      <g filter="url(#filter6_d_0_1)">
        <circle cx="245" cy="109" r="5" fill="#15CB87" />
      </g>
      <defs>
        <filter
          id="filter0_d_0_1"
          x="0"
          y="0"
          width="272.116"
          height="272.116"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-8" dy="16" />
          <feGaussianBlur stdDeviation="20" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_1"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_0_1"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_f_0_1"
          x="-71.0625"
          y="-102.038"
          width="312.917"
          height="312.917"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="40"
            result="effect1_foregroundBlur_0_1"
          />
        </filter>
        <filter
          id="filter2_f_0_1"
          x="80.8188"
          y="46.5058"
          width="294.836"
          height="294.836"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="40"
            result="effect1_foregroundBlur_0_1"
          />
        </filter>
        <filter
          id="filter3_f_0_1"
          x="-185.282"
          y="64.1683"
          width="466.997"
          height="365.817"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="40"
            result="effect1_foregroundBlur_0_1"
          />
        </filter>
        <filter
          id="filter4_d_0_1"
          x="172"
          y="126"
          width="136.182"
          height="136.182"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="12" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_1"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_0_1"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_0_1"
          x="249"
          y="116"
          width="42"
          height="42"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_1"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_0_1"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_d_0_1"
          x="228"
          y="100"
          width="34"
          height="34"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_1"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_0_1"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_0_1">
          <rect
            x="48"
            y="64.5988"
            width="156.862"
            height="156.862"
            rx="32"
            transform="rotate(-15 48 64.5988)"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip1_0_1">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(225.948 161.596) rotate(15)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RegisterApprovedPage;
