import { Amplify } from "aws-amplify";
import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import ConfirmRegisterPage from "./pages/ConfirmRegisterPage";
import CountrySelectPage from "./pages/CountrySelectPage";
import RegisterApprovedPage from "./pages/RegisterApprovedPage";
import RegisterPage from "./pages/RegisterPage";
import { saveToLocalStorage } from "./tools/client-storage";
import { Sources } from "./types";

//========= this is configuration for Production ===========//

Amplify.configure({
  aws_project_region: "ap-southeast-1",
  aws_cognito_identity_pool_id:
    "ap-southeast-1:b090408f-e4a2-4f75-b98d-a4c330e0b4b3",
  aws_cognito_region: "ap-southeast-1",
  aws_user_pools_id: "ap-southeast-1_sGe3JMuy3",
  aws_user_pools_web_client_id: "4l5b68cjnb51r95c7khi0oauls",
  oauth: {},
  aws_cognito_login_mechanisms: ["EMAIL"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OPTIONAL",
  aws_cognito_mfa_types: ["TOTP"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_UPPERCASE",
      "REQUIRES_NUMBERS",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
});

//========= this is configuration for TESTING ===========//

// Amplify.configure({
//   aws_project_region: "ap-southeast-1",
//   aws_cognito_region: "ap-southeast-1",
//   aws_user_pools_id: "ap-southeast-1_WWpnlLjXI",
//   aws_user_pools_web_client_id: "188j971hdh72dvvohad95qreq8",
//   oauth: {},
//   aws_cognito_login_mechanisms: ["EMAIL"],
//   aws_cognito_signup_attributes: ["EMAIL"],
//   aws_cognito_mfa_configuration: "OPTIONAL",
//   aws_cognito_mfa_types: ["TOTP"],
//   aws_cognito_password_protection_settings: {
//     passwordPolicyMinLength: 8,
//     passwordPolicyCharacters: [
//       "REQUIRES_LOWERCASE",
//       "REQUIRES_UPPERCASE",
//       "REQUIRES_NUMBERS",
//     ],
//   },
//   aws_cognito_verification_mechanisms: ["EMAIL"],
// });

const App = () => {
  return (
    <div className="w-full h-full flex justify-center">
      <div className="w-full max-w-[424px]">
        <Routes>
          <Route path="/*" element={<GetSource />} />
          <Route path="/register/country" element={<CountrySelectPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/register/confirm" element={<ConfirmRegisterPage />} />
          <Route path="/register/approved" element={<RegisterApprovedPage />} />
        </Routes>
      </div>
    </div>
  );
};

const GetSource = () => {
  let source: Sources | string | null = "";

  let navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);

  source = params.get("utm_source");

  useEffect(() => {
    if (!!source) {
      saveToLocalStorage("utm-source", { source });

      navigate("/register/country");
    }
  }, []);

  return <></>;
};

export default App;
