import { Icon } from "@reamrd/styledoubler";
import { IconProps } from "@reamrd/styledoubler/dist/icons/Const";
import { SDCountry } from "../types";

const countryOptions: {
  title: string;
  customIcon: (size: "xs" | "sm" | "md" | "lg") => JSX.Element;
  value: SDCountry;
}[] = [
  {
    title: "Indonesia",
    customIcon: (size) => <Icon name="Indonesia" size={size} />,
    value: "id",
  },
  {
    title: "Malaysia",
    customIcon: (size) => <Icon name="Malaysia" size={size} />,
    value: "my",
  },
  {
    title: "Philippines",
    customIcon: (size) => <Icon name="Philippines" size={size} />,
    value: "ph",
  },
  {
    title: "Thailand",
    customIcon: (size) => <Icon name="Thailand" size={size} />,
    value: "th",
  },
];

export { countryOptions };
