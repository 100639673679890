const saveToLocalStorage = (key: StorageKey, value: object | undefined) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.log(e);
  }
};

type StorageKey = "utm-source" | "country";

const readFromLocalStorage = <T>(key: StorageKey): T => {
  try {
    const jsonValue = localStorage.getItem(key);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    console.log(e);
    throw new Error("Invalid");
  }
};

export type UIState = {
  navbarExpanded: boolean;
};

export { readFromLocalStorage, saveToLocalStorage };
