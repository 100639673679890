import { Button, Heading, Paragraph } from "@reamrd/styledoubler";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { countryOptions } from "../mappings/mappings";
import { saveToLocalStorage } from "../tools/client-storage";
import { SDCountry } from "../types";

const CountrySelectPage = () => {
  const [country, setCountry] = React.useState<SDCountry | undefined>();

  let navigate = useNavigate();

  const goToNextScreen = () => {
    navigate("/register");
  };

  return (
    <>
      <div className="h-full pt-16 px-4 flex flex-col">
        <Heading variant="H4" text="Select Your Country" />

        <div className="mt-6 w-full flex flex-col items-start">
          {countryOptions.map(({ title, customIcon, value }, index) => (
            <div
              key={index}
              className={`w-full flex flex-col items-start justify-center p-2 my-2 h-16 border ${
                value === country
                  ? "bg-sd-purple-50 border-sd-purple-500"
                  : "bg-white border-sd-stone-300"
              } hover:border-sd-purple-500 rounded-lg`}
              onClick={() => {
                setCountry(value);
                saveToLocalStorage("country", { country: value });
              }}
            >
              <div className="flex flex-row items-center">
                <div className="ml-2 mr-4">{customIcon("lg")}</div>
                <Paragraph variant="P2" bold text={title} />
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-col mt-6 mb-4">
          <Button
            type="button"
            disabled={!country}
            onClick={goToNextScreen}
            text="Continue"
          />
        </div>
      </div>
    </>
  );
};

export default CountrySelectPage;
